

/*
       If the mount type does not have a max height above the door that it can be mounted (ex. ceiling mount),
       Take the smaller number between the ceiling height minus the OFFSET_FROM_CEILING and the MAX_MOUNTING_HEIGHT.

       If the mount type has a max height above the door that it can be mounted (ex. wall mount),
       Take the smaller number between the ceiling height minus the OFFSET_FROM_CEILING and the MAX_DISTANCE_ABOVE_DOOR
       plus the entryHeight.

       Return that height
        */
// TODO getMaxMountHeight should be done.  I think it is complete.  Could be packaged into a function
import {MAX_MOUNTING_HEIGHT} from "../../utils/settings";

const getMaxMountHeight = (mountType, doorSide, ceilingHeightSwingSide, ceilingHeightNonSwingSide, entryHeight) => {
    let maxHeight = 0;
    if(mountType.MAX_DISTANCE_ABOVE_DOOR === null) {
        (doorSide === 'swing') ?
            maxHeight = Math.min((parseFloat(ceilingHeightSwingSide) - mountType.OFFSET_FROM_CEILING), MAX_MOUNTING_HEIGHT) :
            maxHeight = Math.min((parseFloat(ceilingHeightNonSwingSide) - mountType.OFFSET_FROM_CEILING), MAX_MOUNTING_HEIGHT)
    } else {
        (doorSide === 'swing') ?
            maxHeight = Math.min((parseFloat(ceilingHeightSwingSide) - mountType.OFFSET_FROM_CEILING), (mountType.MAX_DISTANCE_ABOVE_DOOR + entryHeight), MAX_MOUNTING_HEIGHT) :
            maxHeight = Math.min((parseFloat(ceilingHeightNonSwingSide) - mountType.OFFSET_FROM_CEILING), (mountType.MAX_DISTANCE_ABOVE_DOOR + entryHeight), MAX_MOUNTING_HEIGHT)
    }
    console.log('maxMountHeight Result --> ' + maxHeight)
    return maxHeight;
}

export default getMaxMountHeight