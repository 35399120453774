import {useState} from "react";
import UnitsSelectorDisplay from "./UnitSelectorDisplay";
import DimensionsInputDisplay from "./DimensionsInputDisplay";
import ConfigurationInputsDisplay from "./ConfigurationInputDisplay";



const InputsDisplay = props => {



    return (
        <
        >
            {/*<UnitsSelectorDisplay*/}
            {/*    selectedUnits={props.selectedUnits}*/}
            {/*    onClick={props.onUnitChangeButtonClick}*/}
            {/*/>*/}
            <DimensionsInputDisplay
                selectedUnits={props.selectedUnits}
                onChange={props.onInputChange}
                dimensions={props.dimensionInputs}
            />
            <ConfigurationInputsDisplay
                configurationOptions={props.configurationOptions}
                onChange={props.onCheckboxChange}

            />
        </>
    )


}

export default InputsDisplay