import UnitSelectButton from "./UnitSelectButton";


const UnitsSelectorDisplay = props => {
    const units = ["in", "m", "cm", "mm"];
    return (
        <div>
            {units.map(unitId => (
                <UnitSelectButton key={unitId}
                                  unitValue={unitId}
                                  onClick={props.onClick}
                                  selectedUnits={props.selectedUnits}
                >
                </UnitSelectButton>
            ))}
        </div>
    )
}

export default UnitsSelectorDisplay