import getMountTypeParameters from "./getMountTypeParameters";
import {
    CONFIGURATION_OPTIONS,
    MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME, MAX_MOUNTING_HEIGHT,
    WALL_MOUNT
} from "../../utils/settings";
import getMaxMountHeight from "./getMaxMountHeight";
import getPrescribedSensorLocation from "./getPrescribedSensorLocation";
import getSensorSpacing from "../../components/getSensorSpacing";
import handleMultiMPI from "./solutionFactors/handleMultiMPI";


const getWallMountSolution = (dimensionInputs, configurationOptions) => {
    let wallMountSolution = {
        mountTypeSwingSide: null,
        mountTypeNonSwingSide: null,
        mountHeightSwingSide: 0,
        mountHeightNonSwingSide: 0,
        sensorCountSwingSide: 0,
        sensorCountNonSwingSide: 0,
        alignmentSwingSide: 'centered',
        alignmentNonSwingSide: 'centered',
        spacingSwingSide: 'N/A',
        spacingNonSwingSide: 'N/A',
        distanceFromWallSwingSide: null,
        distanceFromWallNonSwingSide: null,
        swingSideFail: false,
        nonSwingSideFail: false
    }



    let installParameters = getMountTypeParameters(WALL_MOUNT,
        dimensionInputs[0]['value'],
        dimensionInputs[1]['value'],
        dimensionInputs[2]['value'],
        dimensionInputs[3]['value'],)

    if(typeof installParameters.minimumMountHeightSwingSide != 'number') {
        wallMountSolution.swingSideFail = true
    }
    if(typeof installParameters.minimumMountHeightNonSwingSide != 'number') {
        wallMountSolution.nonSwingSideFail = true
    }

   // console.log('Step #1 Result: Received the installParameters for Wall Mount --> ')
  //  console.dir(installParameters)

    // /*
    //     Step #2 If no door, raise min height to 100".
    //     If there isn't room to raise it that high, start with an MPI
    //     The purpose of this is to avoid having a sensor offset 10" from
    //     the door frame.  When there is no door, we want the sensor centered,
    //     or to be an MPI.
    //  */
    //
    // // Find the configurationOption for HAS_A_DOOR
    // for(let i = 0, l = configurationOptions.length; i < l; i++) {
    //     let currentConfigOption = configurationOptions[i];
    //     // If we find it and it is not checked (as in there is not a door)
    //     if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
    //       //  console.log('This entryway does not have a door')
    //         //Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!wallMountSolution.swingSideFail) {
    //             installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightSwingSide): installParameters.startingSensorCountSwingSide = 2
    //         }
    //
    //         //Non-Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!wallMountSolution.nonSwingSideFail) {
    //             installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2
    //         }
    //
    //         break
    //     }
    // }
    // // console.log('Step #2 Result: Has a Door Step ')
    // // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)

    /*
        Step #3 If there are sliding doors, raise the min height to 100".
        If there isn't room to raise it that high, start with an MPI
        The purpose of this is to avoid having a sensor offset 10" from
        the door frame.  When there are sliding doors, we want the sensor centered,
        or to be an MPI
     */
    // Find the configurationOption for HAS_SLIDING_DOORS

    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there are sliding doors)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_SLIDING_DOORS && currentConfigOption.isChecked) {
          //  console.log('This entryway has sliding doors')
            //Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!wallMountSolution.swingSideFail) {
                if(installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME) {
                    installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,installParameters.minimumMountHeightSwingSide)
                } else {
                    installParameters.startingSensorCountSwingSide = 2
                }
            }

            // //Non-Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!wallMountSolution.nonSwingSideFail) {
                installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
                    // Set the minMountingHeight to the max between 100" and minMountingHeight
                    installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
                        // Else, set the startingSensorCount = 2.
                        installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2

            }

            break
        }
    }
    // console.log('Step #3 Result: Has sliding doors step ')
    // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)

    /*
        Step #4 If there is a deep door closer, fail the wall mount
        for the side that it is on.  Currently, we just fail the swing side.
        Should ask which side and fail that side.
     */
    // Find the configurationOption for HAS_DEEP_DOOR_OPENER
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is a deep opener)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_DEEP_DOOR_OPENER && currentConfigOption.isChecked) {
           // console.log('This entryway has a deep door opener')
            //Swing Side
            // For now, we just fail the swing side if this is the case.
            // the correct fix will be to ask which side it is on and only fail that side.
            //future - make this smarter
            wallMountSolution.swingSideFail = true


            //Non-swing Side
            if(currentConfigOption.side === 'non-swing') {
                // set the ceiling height to the min between the entryHeight + opener height, and existing ceiling
                installParameters.ceilingHeightNonSwingSide = Math.min(
                    (installParameters.entryHeight + configurationOptions.height),
                    installParameters.ceilingHeightNonSwingSide)

                //get the new maxMountHeight with the new ceilingHeight
                installParameters.maximumMountHeightNonSwingSide = getMaxMountHeight(
                    WALL_MOUNT,
                    'swing',
                    installParameters.ceilingHeightSwingSide,
                    installParameters.ceilingHeightNonSwingSide,
                    installParameters.entryHeight)

            }

            break
        }


        // TODO I'm not sure if we need to do this
        // validate that it will fit (ceiling height becomes door height + 6" + ceiling offset)
    }


    /*
        Step #5 If the wall is glass, fail the wall mount
     */
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is a glass wall)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_GLASS_WALL && currentConfigOption.isChecked) {
           wallMountSolution.swingSideFail = true;
           wallMountSolution.nonSwingSideFail = true;
        }
    }
   // console.log('Step #5 Result: Has a glass wall step ')


    /*
        Step #6 If ambient light, make starting sensor count = 2
     */
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is ambient light)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_AMBIENT_LIGHT && currentConfigOption.isChecked) {
            installParameters.startingSensorCountSwingSide = 2
            installParameters.startingSensorCountNonSwingSide = 2
        }
    }
    // console.log('Step #6 Result: Has ambient light step ')
    // console.log('startingSensorCountSwingSide --> ' + installParameters.startingSensorCountSwingSide)
    // console.log('startingSensorCountNonSwingSide --> ' + installParameters.startingSensorCountNonSwingSide)

    /*
        Step #7 Calculate the sensor height
     */
    let prescribedLocation = getPrescribedSensorLocation(installParameters)
    wallMountSolution.mountHeightSwingSide = prescribedLocation.prescribedHeightSwingSide
    wallMountSolution.sensorCountSwingSide = prescribedLocation.prescribedCountSwingSide
    wallMountSolution.mountHeightNonSwingSide = prescribedLocation.prescribedHeightNonSwingSide
    wallMountSolution.sensorCountNonSwingSide = prescribedLocation.prescribedCountNonSwingSide


    /*
        Step #8 If sensor count = 1 and height is < 100", and there is a door, offset sensor 10" from door handle side
     */
    let hasADoor = true;
    // Find the configurationOption for HAS_A_DOOR
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is not checked (as in there is not a door)
        if (currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
            // console.log('This entryway does not have a door')
            hasADoor = false;
            break;
        }
    }
    wallMountSolution.alignmentSwingSide = (prescribedLocation.prescribedCountSwingSide === 1 && prescribedLocation.prescribedHeightSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';

    wallMountSolution.alignmentNonSwingSide = (prescribedLocation.prescribedCountNonSwingSide === 1 && prescribedLocation.prescribedHeightNonSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';


    /*
        Step #9 if MPI > 2, and prescribed height is less than 95", move to 95" and validate that it will fit.
     */


    wallMountSolution.mountHeightSwingSide = handleMultiMPI(WALL_MOUNT,'swing', wallMountSolution.sensorCountSwingSide, wallMountSolution.mountHeightSwingSide,installParameters.maximumMountHeightSwingSide)
    wallMountSolution.mountHeightNonSwingSide = handleMultiMPI(WALL_MOUNT,'non-swing', wallMountSolution.sensorCountNonSwingSide, wallMountSolution.mountHeightNonSwingSide,installParameters.maximumMountHeightNonSwingSide)
    if (typeof wallMountSolution.mountHeightSwingSide !== 'number') {
        wallMountSolution.swingSideFail = true;
    }
    if (typeof wallMountSolution.mountHeightNonSwingSide !== 'number') {
        wallMountSolution.nonSwingSideFail = true;
    }

    //
    // if(wallMountSolution.sensorCountSwingSide > 2 && wallMountSolution.mountHeightSwingSide < MULTI_MPI_MIN_MOUNTING_HEIGHT) {
    //     let tempEntryHeight = MULTI_MPI_MIN_MOUNTING_HEIGHT -  WALL_MOUNT.OFFSET_FROM_TOP_OF_DOOR
    //     wallMountSolution.mountHeightSwingSide = (validateSensorFit(WALL_MOUNT, 'swing', tempEntryHeight, MULTI_MPI_MIN_MOUNTING_HEIGHT, installParameters.maximumMountHeightSwingSide)) ?
    //         MULTI_MPI_MIN_MOUNTING_HEIGHT : 'N/A'
    // }
    //
    // if(wallMountSolution.sensorCountNonSwingSide > 2 && wallMountSolution.mountHeightNonSwingSide < MULTI_MPI_MIN_MOUNTING_HEIGHT) {
    //     let tempEntryHeight = MULTI_MPI_MIN_MOUNTING_HEIGHT -  WALL_MOUNT.OFFSET_FROM_TOP_OF_DOOR
    //     wallMountSolution.mountHeightNonSwingSide = (validateSensorFit(WALL_MOUNT, 'non-swing', tempEntryHeight, MULTI_MPI_MIN_MOUNTING_HEIGHT, installParameters.maximumMountHeightNonSwingSide)) ?
    //         MULTI_MPI_MIN_MOUNTING_HEIGHT : 'N/A'
    // }

    /*
        Step #10 Calculate the spacing for the MPI
     */

    wallMountSolution.spacingSwingSide = (wallMountSolution.sensorCountSwingSide > 1 && wallMountSolution.mountHeightSwingSide !== 'N/A') ?
        getSensorSpacing(wallMountSolution.mountHeightSwingSide) : 'N/A'

    wallMountSolution.spacingNonSwingSide = (wallMountSolution.sensorCountNonSwingSide > 1 && wallMountSolution.mountHeightNonSwingSide !== 'N/A') ?
        getSensorSpacing(wallMountSolution.mountHeightNonSwingSide) : 'N/A'


    /**
     * Make sure that the solution does not exceed the MAX_MOUNTING_HEIGHT
     */
    if(wallMountSolution.mountHeightSwingSide > MAX_MOUNTING_HEIGHT) {
        wallMountSolution.swingSideFail = true;
    }
    if(wallMountSolution.mountHeightNonSwingSide > MAX_MOUNTING_HEIGHT) {
        wallMountSolution.nonSwingSideFail = true;
    }


    return wallMountSolution
}


export default getWallMountSolution