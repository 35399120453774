import {Checkbox, FormControlLabel} from "@material-ui/core";

const ConfigurationInput = props => {

    return (
        <FormControlLabel
            control={<Checkbox
                checked={props.isChecked}
                onChange={props.onChange}
                // onChange={(e) => props.onChange(props.id, e.target.checked)}
                id={props.id} />}
            label={props.label}
        >
        </FormControlLabel>
    )
}

export default ConfigurationInput