import Typography from "@material-ui/core/Typography";
import {InputAdornment, TextField} from "@material-ui/core";
import React, {useState} from "react";


const PowerCalculator = () => {

    const [entryPower, setEntryPower] = useState(0)
    const [oaPower, setOaPower] = useState(0)

    const handleEntryChange = (e) => {
        setEntryPower((entryPower) => e.target.value * 30)
    }
    const handleOaChange = (e) => {
        setOaPower((oaPower) => e.target.value * 15.4)
    }

    return (
        <div>
            <Typography color="inherit" variant="h5" component="h1">
                Calculates power requirements
            </Typography>
            <TextField
                className="dimension-input"
                label="Entry Sensor Quantity"
                onChange={handleEntryChange}
                margin="dense"
                size="small"
                color="primary"
                variant="outlined"

            />
            <TextField
                className="dimension-input"
                label="OA Sensor Quantity"
                onChange={handleOaChange}
                margin="dense"
                size="small"
                color="primary"
                variant="outlined"

            />
            <Typography color="inherit" variant="h5" component="h1">
                {entryPower + oaPower} Watts of Power Required in Total
            </Typography>
        </div>
    )
}
export default PowerCalculator