import {Button} from "@material-ui/core";



const UnitSelectButton = props => {
    return (
        <Button
            className="units-button"
            onClick={() => props.onClick(props.unitValue)}
            style={{backgroundColor: props.selectedUnits === props.unitValue ?
                    '#009be5' : 'rgba(255, 255, 255, 0.7)'}}
        >
            {props.unitValue}
        </Button>

    )

}

export default UnitSelectButton