import getMountTypeParameters from "./getMountTypeParameters";
import {
    CEILING_MOUNT,
    CONFIGURATION_OPTIONS, MAX_HEIGHT_ABOVE_DOOR_BEFORE_OFFSET_FROM_WALL,
    MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME, MAX_MOUNTING_HEIGHT,

} from "../../utils/settings";
import getPrescribedSensorLocation from "./getPrescribedSensorLocation";

import getSensorSpacing from "../../components/getSensorSpacing";
import handleMultiMPI from "./solutionFactors/handleMultiMPI";

const getCeilingMountSolution = (dimensionInputs, configurationOptions) => {
    let ceilingMountSolution = {
        mountTypeSwingSide: null,
        mountTypeNonSwingSide: null,
        mountHeightSwingSide: 0,
        mountHeightNonSwingSide: 0,
        sensorCountSwingSide: 0,
        sensorCountNonSwingSide: 0,
        alignmentSwingSide: 'centered',
        alignmentNonSwingSide: 'centered',
        spacingSwingSide: 'N/A',
        spacingNonSwingSide: 'N/A',
        distanceFromWallSwingSide: 0,
        distanceFromWallNonSwingSide: 0,
        swingSideFail: false,
        nonSwingSideFail: false
    }
    
    let installParameters = getMountTypeParameters(CEILING_MOUNT,
        dimensionInputs[0]['value'],
        dimensionInputs[1]['value'],
        dimensionInputs[2]['value'],
        dimensionInputs[3]['value'],)
    // console.log('Step #1 Result: Received the installParameters for Ceiling Mount --> ')
    // console.dir(installParameters)
    if(typeof installParameters.minimumMountHeightSwingSide != 'number') {
        ceilingMountSolution.swingSideFail = true
    }
    if(typeof installParameters.minimumMountHeightNonSwingSide != 'number') {
        ceilingMountSolution.nonSwingSideFail = true
    }

    // /*
    //     Step #2 If no door, raise min height to 100".
    //     If there isn't room to raise it that high, start with an MPI
    //     The purpose of this is to avoid having a sensor offset 10" from
    //     the door frame.  When there is no door, we want the sensor centered,
    //     or to be an MPI.
    //  */
    //
    // // Find the configurationOption for HAS_A_DOOR
    // for(let i = 0, l = configurationOptions.length; i < l; i++) {
    //     let currentConfigOption = configurationOptions[i];
    //     // If we find it and it is not checked (as in there is not a door)
    //     if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
    //         // console.log('This entryway does not have a door')
    //         //Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!ceilingMountSolution.swingSideFail) {
    //             installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightSwingSide): installParameters.startingSensorCountSwingSide = 2
    //         }
    //
    //         //Non-Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!ceilingMountSolution.nonSwingSideFail) {
    //             installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2
    //         }
    //
    //         break
    //     }
    // }
    // // console.log('Step #2 Result: Has a Door Step ')
    // // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)

    /*
           Step #3 If there are sliding doors, raise the min height to 100".
           If there isn't room to raise it that high, start with an MPI
           The purpose of this is to avoid having a sensor offset 10" from
           the door frame.  When there are sliding doors, we want the sensor centered,
           or to be an MPI
        */
    // Find the configurationOption for HAS_SLIDING_DOORS
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there are sliding doors)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_SLIDING_DOORS && currentConfigOption.isChecked) {
            // console.log('This entryway has sliding doors')
            //Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!ceilingMountSolution.swingSideFail) {
                installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
                    // Set the minMountingHeight to the max between 100" and minMountingHeight
                    installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
                        // Else, set the startingSensorCount = 2.
                        installParameters.minimumMountHeightSwingSide): installParameters.startingSensorCountSwingSide = 2
            }

            //Non-Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!ceilingMountSolution.nonSwingSideFail) {
                installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
                    // Set the minMountingHeight to the max between 100" and minMountingHeight
                    installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
                        // Else, set the startingSensorCount = 2.
                        installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2
            }

            break
        }
    }
    // console.log('Step #3 Result: Has sliding doors step ')
    // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)



    /*
       Step #4 If there is a deep door closer, mount in front of it.
    */
    // Find the configurationOption for HAS_DEEP_DOOR_OPENER
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is a deep opener)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_DEEP_DOOR_OPENER && currentConfigOption.isChecked) {
            // console.log('This entryway has a deep door opener')
            //Swing Side
            if(currentConfigOption.side === 'swing') {
                ceilingMountSolution.distanceFromWallNonSwingSide = 'In Front of Door Opener'

            }
            //Non-swing Side
            if(currentConfigOption.side === 'non-swing') {
                ceilingMountSolution.distanceFromWallNonSwingSide = 'In Front of Door Opener'

            }

            break
        }


    }



    /*
        Step #5 If ambient light, make starting sensor count = 2
     */
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is ambient light)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_AMBIENT_LIGHT && currentConfigOption.isChecked) {
            installParameters.startingSensorCountSwingSide = 2
            installParameters.startingSensorCountNonSwingSide = 2
        }
    }
    // console.log('Step #6 Result: Has ambient light step ')
    // console.log('startingSensorCountSwingSide --> ' + installParameters.startingSensorCountSwingSide)
    // console.log('startingSensorCountNonSwingSide --> ' + installParameters.startingSensorCountNonSwingSide)


    /*
                Step #7 Calculate the sensor height
             */
    console.log('Ceiling Mount - Going to get sensors prescribed height')
    console.log('maxMounting Height to be used: ' + installParameters.maximumMountHeightSwingSide)
    let prescribedLocation = getPrescribedSensorLocation(installParameters)
    ceilingMountSolution.mountHeightSwingSide = prescribedLocation.prescribedHeightSwingSide
    ceilingMountSolution.sensorCountSwingSide = prescribedLocation.prescribedCountSwingSide
    ceilingMountSolution.mountHeightNonSwingSide = prescribedLocation.prescribedHeightNonSwingSide
    ceilingMountSolution.sensorCountNonSwingSide = prescribedLocation.prescribedCountNonSwingSide
    
    
    /*
        Step #8 If the rod is longer than 30", display a warning
        
     */
    //TODO need to implement the rod warning.


    /*
        Step #9 If sensor count = 1 and height is < 100", and there is a door, offset sensor 10" from door handle side
     */
    let hasADoor = true;
    // Find the configurationOption for HAS_A_DOOR
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is not checked (as in there is not a door)
        if (currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
            // console.log('This entryway does not have a door')
            hasADoor = false;
            break;
        }
    }
    ceilingMountSolution.alignmentSwingSide = (prescribedLocation.prescribedCountSwingSide === 1 && prescribedLocation.prescribedHeightSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';

    ceilingMountSolution.alignmentNonSwingSide = (prescribedLocation.prescribedCountNonSwingSide === 1 && prescribedLocation.prescribedHeightNonSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';
    
    
    /*
        Step #10 If the sensor is more than 10" above the door, move the sensor 2" away from the wall
     */
    ceilingMountSolution.distanceFromWallSwingSide = ((prescribedLocation.prescribedHeightSwingSide - installParameters.entryHeight) > MAX_HEIGHT_ABOVE_DOOR_BEFORE_OFFSET_FROM_WALL) ?
        2 : 0

    ceilingMountSolution.distanceFromWallNonSwingSide = ((prescribedLocation.prescribedHeightNonSwingSide - installParameters.entryHeight) > MAX_HEIGHT_ABOVE_DOOR_BEFORE_OFFSET_FROM_WALL) ?
        2 : 0




    /*
       Step #11 if MPI > 2, and prescribed height is less than 95", move to 95" and validate that it will fit.
    */
    ceilingMountSolution.mountHeightSwingSide = handleMultiMPI(CEILING_MOUNT,'swing', ceilingMountSolution.sensorCountSwingSide, ceilingMountSolution.mountHeightSwingSide,installParameters.maximumMountHeightSwingSide)
    ceilingMountSolution.mountHeightNonSwingSide = handleMultiMPI(CEILING_MOUNT,'non-swing', ceilingMountSolution.sensorCountNonSwingSide, ceilingMountSolution.mountHeightNonSwingSide,installParameters.maximumMountHeightNonSwingSide)
    if (typeof ceilingMountSolution.mountHeightSwingSide !== 'number') {
        ceilingMountSolution.swingSideFail = true;
    }
    if (typeof ceilingMountSolution.mountHeightNonSwingSide !== 'number') {
        ceilingMountSolution.nonSwingSideFail = true;
    }


    /*
       Step #12 Calculate the spacing for the MPI
    */

    ceilingMountSolution.spacingSwingSide = (ceilingMountSolution.sensorCountSwingSide > 1 && ceilingMountSolution.mountHeightSwingSide !== 'N/A') ?
        getSensorSpacing(ceilingMountSolution.mountHeightSwingSide) : 'N/A'

    ceilingMountSolution.spacingNonSwingSide = (ceilingMountSolution.sensorCountNonSwingSide > 1 && ceilingMountSolution.mountHeightNonSwingSide !== 'N/A') ?
        getSensorSpacing(ceilingMountSolution.mountHeightNonSwingSide) : 'N/A'


    /**
     * Make sure that the solution does not exceed the MAX_MOUNTING_HEIGHT
     */
    if(ceilingMountSolution.mountHeightSwingSide > MAX_MOUNTING_HEIGHT) {
        ceilingMountSolution.swingSideFail = true;
    }
    if(ceilingMountSolution.mountHeightNonSwingSide > MAX_MOUNTING_HEIGHT) {
        ceilingMountSolution.nonSwingSideFail = true;
    }

    return ceilingMountSolution
}

export default getCeilingMountSolution