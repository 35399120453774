import {PREFERRED_MIN_MOUNTING_HEIGHT} from "../../utils/settings";
import getMaxMountHeight from "./getMaxMountHeight";
import validateSensorFit from "./validateSensorFit";

/**
 *
 * @param mountType - The pre-defined object that contains the parameters specific to each mount type. (set in settings.js)
 * @param width - The width of the doorway (inches)
 * @param height - The height of the doorway (inches)
 * @param ceilingNonSwingSide - The height from floor to ceiling on the non-swing side of the doorway
 * @param ceilingSwingSide - The height from floor to ceiling on the swing side of the doorway
 * @returns {{entryWidth: number, ceilingHeightNonSwingSide: number, minimumMountHeightNonSwingSide: (string|number), ceilingHeightSwingSide: number, maximumMountHeightSwingSide: number, startingSensorCountSwingSide: number, entryHeight: number, startingSensorCountNonSwingSide: number, minimumMountHeightSwingSide: (string|number), maximumMountHeightNonSwingSide: number, mountType}}
 */

const getMountTypeParameters = (mountType, width, height, ceilingNonSwingSide, ceilingSwingSide) => {

    let entryHeight = parseFloat(height)
    let entryWidth = parseFloat(width)
    let ceilingHeightSwingSide = parseFloat(ceilingSwingSide)
    let ceilingHeightNonSwingSide = parseFloat(ceilingNonSwingSide)


    const getMinMountHeight = (doorSide) => {

        /*
        TODO This description should be close, but double check
            if the minimum height is higher than the max height,
            check to see if there is enough space to fit over the door way from max height.
            If there is enough height, check that it is still 90 or higher
            if there isn't enough height, check if we can hang lower than the door on non-swing side.  if we can,
            check that we are 90 or higher.
         */


        // Find which is larger
        let minMountHeight = Math.max(PREFERRED_MIN_MOUNTING_HEIGHT, (entryHeight + mountType.OFFSET_FROM_TOP_OF_DOOR))
       // console.log('starting height --> ' + minMountHeight)
        const maxMountHeight = getMaxMountHeight(mountType, doorSide, ceilingHeightSwingSide, ceilingHeightNonSwingSide, entryHeight)
        // If the min height needed is higher than the max, set min equal to max and see if it works
        if (minMountHeight > maxMountHeight) {
            minMountHeight = maxMountHeight;
        }
        console.log('Mounting Height Result for ' + doorSide + ' --> ' + minMountHeight + ', ' + maxMountHeight)
        return (validateSensorFit(mountType, doorSide, entryHeight, minMountHeight, maxMountHeight)) ? minMountHeight : 'Will Not Fit'



    }



    const returnParameters = {
        mountType: mountType,
        entryWidth: entryWidth,
        entryHeight: entryHeight,
        ceilingHeightSwingSide: ceilingHeightSwingSide,
        ceilingHeightNonSwingSide: ceilingHeightNonSwingSide,
        minimumMountHeightSwingSide: getMinMountHeight('swing'),
        minimumMountHeightNonSwingSide: getMinMountHeight('non-swing'),
        maximumMountHeightSwingSide: getMaxMountHeight(mountType, 'swing', ceilingHeightSwingSide, ceilingHeightNonSwingSide, entryHeight),
        maximumMountHeightNonSwingSide: getMaxMountHeight(mountType, 'non-swing', ceilingHeightSwingSide, ceilingHeightNonSwingSide, entryHeight),
        startingSensorCountSwingSide: 1,
        startingSensorCountNonSwingSide: 1,
    }

    return returnParameters



}
export default getMountTypeParameters