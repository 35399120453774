import ConfigurationInput from "./ConfigurationInput";


const ConfigurationInputsDisplay = props => {

    return (
        <div>
            {props.configurationOptions.map(configOption => (
                <ConfigurationInput
                    key={configOption.id}
                    id={configOption.id}
                    label={configOption.text}
                    isChecked={configOption.isChecked}
                    onChange={props.onChange(configOption.id)}
                ></ConfigurationInput>
            ))}
        </div>
    )



}

export default ConfigurationInputsDisplay