import ResultsTable from "./ResultsTable";


const ResultsDisplay = props => {
    function createData(mountType, numOfSensors, height, alignment, spacing, distFromWall, fail) {
        return {mountType, numOfSensors, height, alignment, spacing, distFromWall, fail}
    }
    let results = props.results
    const swingSideRows = [
        createData('Wall',
            results.wallMount.sensorCountSwingSide,
            results.wallMount.mountHeightSwingSide,
            results.wallMount.alignmentSwingSide,
            results.wallMount.spacingSwingSide,
            results.wallMount.distanceFromWallSwingSide,
            results.wallMount.swingSideFail),
        createData('Ceiling',
            results.ceilingMount.sensorCountSwingSide,
            results.ceilingMount.mountHeightSwingSide,
            results.ceilingMount.alignmentSwingSide,
            results.ceilingMount.spacingSwingSide,
            results.ceilingMount.distanceFromWallSwingSide,
            results.ceilingMount.swingSideFail),
        createData('Low Profile',
            results.lowProfileMount.sensorCountSwingSide,
            results.lowProfileMount.mountHeightSwingSide,
            results.lowProfileMount.alignmentSwingSide,
            results.lowProfileMount.spacingSwingSide,
            results.lowProfileMount.distanceFromWallSwingSide,
            results.lowProfileMount.swingSideFail),
        createData('Adhesive',
            results.adhesiveMount.sensorCountSwingSide,
            results.adhesiveMount.mountHeightSwingSide,
            results.adhesiveMount.alignmentSwingSide,
            results.adhesiveMount.spacingSwingSide,
            results.adhesiveMount.distanceFromWallSwingSide,
            results.adhesiveMount.swingSideFail),
        // createData('Door Frame', 1, 98, 'center', 'N/A', 'N/A'),
    ];
    const nonSwingSideRows = [
        createData('Wall',
            results.wallMount.sensorCountNonSwingSide,
            results.wallMount.mountHeightNonSwingSide,
            results.wallMount.alignmentNonSwingSide,
            results.wallMount.spacingNonSwingSide,
            results.wallMount.distanceFromWallNonSwingSide,
            results.wallMount.nonSwingSideFail),
        createData('Ceiling',
            results.ceilingMount.sensorCountNonSwingSide,
            results.ceilingMount.mountHeightNonSwingSide,
            results.ceilingMount.alignmentNonSwingSide,
            results.ceilingMount.spacingNonSwingSide,
            results.ceilingMount.distanceFromWallNonSwingSide,
            results.ceilingMount.nonSwingSideFail),
        createData('Low Profile',
            results.lowProfileMount.sensorCountNonSwingSide,
            results.lowProfileMount.mountHeightNonSwingSide,
            results.lowProfileMount.alignmentNonSwingSide,
            results.lowProfileMount.spacingNonSwingSide,
            results.lowProfileMount.distanceFromWallNonSwingSide,
            results.lowProfileMount.nonSwingSideFail),
        createData('Adhesive',
            results.adhesiveMount.sensorCountNonSwingSide,
            results.adhesiveMount.mountHeightNonSwingSide,
            results.adhesiveMount.alignmentNonSwingSide,
            results.adhesiveMount.spacingNonSwingSide,
            results.adhesiveMount.distanceFromWallNonSwingSide,
            results.adhesiveMount.nonSwingSideFail),
        // // createData('Door Frame', 1, 98, 'center', 'N/A', 'N/A'),
    ];

    return (
        <div>
            <ResultsTable
                rows={nonSwingSideRows}
                tableTitle="Non-Swing Side"
            ></ResultsTable>
            <ResultsTable
                rows={swingSideRows}
                tableTitle="Swing Side"
            ></ResultsTable>

        </div>

    )

}

export default ResultsDisplay