import {MULTI_MPI_MIN_MOUNTING_HEIGHT, WALL_MOUNT} from "../../../utils/settings";
import validateSensorFit from "../validateSensorFit";


const handleMultiMPI = (mountType, mountSide, sensorCount, mountHeight, maxMountHeight) => {

    if(sensorCount > 2 && mountHeight < MULTI_MPI_MIN_MOUNTING_HEIGHT) {
        let tempEntryHeight = MULTI_MPI_MIN_MOUNTING_HEIGHT -  WALL_MOUNT.OFFSET_FROM_TOP_OF_DOOR
        let result = (validateSensorFit(mountType, mountSide, tempEntryHeight, MULTI_MPI_MIN_MOUNTING_HEIGHT, maxMountHeight)) ?
            MULTI_MPI_MIN_MOUNTING_HEIGHT : 'N/A'
        return result
    } else {
        return mountHeight
    }
}

export default handleMultiMPI