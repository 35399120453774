import getWallMountSolution from "./getWallMountSolution";
import getLowProfileMountSolution from "./getLowProfileMountSolution";
import getCeilingMountSolution from "./getCeilingMountSolution";
import getAdhesiveMountSolution from "./getAdhesiveMountSolution";
import convert from "convert-units";


/**
 *
 * @param dimensionInputs - These are the dimensions that were entered by the user
 * @param configurationOptions - These are the checkbox options that the user set
 * @param selectedUnits - This is the unit of measure selected by the user
 * @returns {{wallMount: {distanceFromWallSwingSide: number, mountHeightNonSwingSide: number, alignmentSwingSide: string, alignmentNonSwingSide: string, mountTypeSwingSide: null, sensorCountSwingSide: number, spacingSwingSide: string, swingSideFail: boolean, mountHeightSwingSide: number, mountTypeNonSwingSide: null, nonSwingSideFail: boolean, spacingNonSwingSide: string, distanceFromWallNonSwingSide: number, sensorCountNonSwingSide: number}, lowProfileMount: {distanceFromWallSwingSide: number, mountHeightNonSwingSide: number, alignmentSwingSide: string, alignmentNonSwingSide: string, mountTypeSwingSide: null, sensorCountSwingSide: number, spacingSwingSide: string, swingSideFail: boolean, mountHeightSwingSide: number, mountTypeNonSwingSide: null, nonSwingSideFail: boolean, spacingNonSwingSide: string, distanceFromWallNonSwingSide: number, sensorCountNonSwingSide: number}, ceilingMount: {distanceFromWallSwingSide: number, mountHeightNonSwingSide: number, alignmentSwingSide: string, alignmentNonSwingSide: string, mountTypeSwingSide: null, sensorCountSwingSide: number, spacingSwingSide: string, swingSideFail: boolean, mountHeightSwingSide: number, mountTypeNonSwingSide: null, nonSwingSideFail: boolean, spacingNonSwingSide: string, distanceFromWallNonSwingSide: number, sensorCountNonSwingSide: number}, adhesiveMount: {mountHeightNonSwingSide: number, alignmentSwingSide: string, alignmentNonSwingSide: string, mountTypeSwingSide: null, sensorCountSwingSide: number, spacingSwingSide: string, distanceFromAdhesiveNonSwingSide: number, swingSideFail: boolean, mountHeightSwingSide: number, mountTypeNonSwingSide: null, nonSwingSideFail: boolean, spacingNonSwingSide: string, sensorCountNonSwingSide: number, distanceFromAdhesiveSwingSide: number}}}
 */

const getMountingSolution = (dimensionInputs, configurationOptions, selectedUnits) => {

    /*
    Step #1 if the selectedUnits are not inches, convert the dimensionsInputs to inches.
    All calculations are done in inches.
     */
    let convertedInputs = dimensionInputs;
    // let val1 = 5
    // let val2 = val1
    // if (selectedUnits !== "in") {
    //     for(let i = 0; i < convertedInputs.length; i++) {
    //         console.log(dimensionInputs[i].value)
    //         convertedInputs[i].value = convert(convertedInputs[i].value).from(selectedUnits).to('in')
    //         // convertedInputs[i].value = convertedInputs[i].value + 1
    //         console.log(convertedInputs[i].value)
    //         console.log(dimensionInputs[i].value)
    //         console.log('val1 ' + val1)
    //         val2 = convert(val1).from(selectedUnits).to('in')
    //         console.log('val2 ' + val2)
    //         console.log('val1 again ' + val1)
    //
    //     }
    //
    // }

    const roundResult = (value) => {
        return (typeof value === 'number')? Math.round(value) : value
    }


    /*
    Step #2 Use the converted dimensions to get the mounting solutions.
     */
    // let solution = {
    //     wallMount: getWallMountSolution(convertedInputs, configurationOptions),
    //     lowProfileMount: getLowProfileMountSolution(convertedInputs, configurationOptions),
    //     ceilingMount: getCeilingMountSolution(convertedInputs, configurationOptions),
    //     adhesiveMount: getAdhesiveMountSolution(convertedInputs, configurationOptions),
    //
    // }
    let solution = {
        wallMount: getWallMountSolution(dimensionInputs, configurationOptions),
        lowProfileMount: getLowProfileMountSolution(dimensionInputs, configurationOptions),
        ceilingMount: getCeilingMountSolution(dimensionInputs, configurationOptions),
        adhesiveMount: getAdhesiveMountSolution(dimensionInputs, configurationOptions),

    }


    const convertResult = (value) => {
        return (typeof value === 'number') ? convert(value).from(selectedUnits).to('in') : value
    }





    /*
    Step #3 If selectedUnits are not inches, convert the results from previous step
    from inches to the selectedUnits
     */
    if (selectedUnits !== "in") {
        solution.wallMount.mountHeightSwingSide = convertResult(solution.wallMount.mountHeightSwingSide)
        solution.wallMount.mountHeightNonSwingSide = convertResult(solution.wallMount.mountHeightNonSwingSide)
        solution.wallMount.spacingSwingSide = convertResult(solution.wallMount.spacingSwingSide)
        solution.wallMount.spacingNonSwingSide = convertResult(solution.wallMount.spacingNonSwingSide)

        solution.lowProfileMount.mountHeightSwingSide = convertResult(solution.lowProfileMount.mountHeightSwingSide)
        solution.lowProfileMount.mountHeightNonSwingSide = convertResult(solution.lowProfileMount.mountHeightNonSwingSide)
        solution.lowProfileMount.spacingSwingSide = convertResult(solution.lowProfileMount.spacingSwingSide)
        solution.lowProfileMount.spacingNonSwingSide = convertResult(solution.lowProfileMount.spacingNonSwingSide)

        solution.ceilingMount.mountHeightSwingSide = convertResult(solution.ceilingMount.mountHeightSwingSide)
        solution.ceilingMount.mountHeightNonSwingSide = convertResult(solution.ceilingMount.mountHeightNonSwingSide)
        solution.ceilingMount.spacingSwingSide = convertResult(solution.ceilingMount.spacingSwingSide)
        solution.ceilingMount.spacingNonSwingSide = convertResult(solution.ceilingMount.spacingNonSwingSide)

        solution.adhesiveMount.mountHeightSwingSide = convertResult(solution.adhesiveMount.mountHeightSwingSide)
        solution.adhesiveMount.mountHeightNonSwingSide = convertResult(solution.adhesiveMount.mountHeightNonSwingSide)
        solution.adhesiveMount.spacingSwingSide = convertResult(solution.adhesiveMount.spacingSwingSide)
        solution.adhesiveMount.spacingNonSwingSide = convertResult(solution.adhesiveMount.spacingNonSwingSide)

    }


    
    const fixDecimal = (value) => {
        return (typeof value === 'number') ? value.toFixed(2) : value
    }

    if (selectedUnits !== "m") {
        solution.wallMount.mountHeightSwingSide = roundResult(solution.wallMount.mountHeightSwingSide)
        solution.wallMount.mountHeightNonSwingSide = roundResult(solution.wallMount.mountHeightNonSwingSide)
        solution.wallMount.spacingSwingSide = roundResult(solution.wallMount.spacingSwingSide)
        solution.wallMount.spacingNonSwingSide = roundResult(solution.wallMount.spacingNonSwingSide)

        solution.lowProfileMount.mountHeightSwingSide = roundResult(solution.lowProfileMount.mountHeightSwingSide)
        solution.lowProfileMount.mountHeightNonSwingSide = roundResult(solution.lowProfileMount.mountHeightNonSwingSide)
        solution.lowProfileMount.spacingSwingSide = roundResult(solution.lowProfileMount.spacingSwingSide)
        solution.lowProfileMount.spacingNonSwingSide = roundResult(solution.lowProfileMount.spacingNonSwingSide)

        solution.ceilingMount.mountHeightSwingSide = roundResult(solution.ceilingMount.mountHeightSwingSide)
        solution.ceilingMount.mountHeightNonSwingSide = roundResult(solution.ceilingMount.mountHeightNonSwingSide)
        solution.ceilingMount.spacingSwingSide = roundResult(solution.ceilingMount.spacingSwingSide)
        solution.ceilingMount.spacingNonSwingSide = roundResult(solution.ceilingMount.spacingNonSwingSide)

        solution.adhesiveMount.mountHeightSwingSide = roundResult(solution.adhesiveMount.mountHeightSwingSide)
        solution.adhesiveMount.mountHeightNonSwingSide = roundResult(solution.adhesiveMount.mountHeightNonSwingSide)
        solution.adhesiveMount.spacingSwingSide = roundResult(solution.adhesiveMount.spacingSwingSide)
        solution.adhesiveMount.spacingNonSwingSide = roundResult(solution.adhesiveMount.spacingNonSwingSide)

    } else {
        solution.wallMount.mountHeightSwingSide = fixDecimal(solution.wallMount.mountHeightSwingSide)
        solution.wallMount.mountHeightNonSwingSide = fixDecimal(solution.wallMount.mountHeightNonSwingSide)
        solution.wallMount.spacingSwingSide = fixDecimal(solution.wallMount.spacingSwingSide)
        solution.wallMount.spacingNonSwingSide = fixDecimal(solution.wallMount.spacingNonSwingSide)

        solution.lowProfileMount.mountHeightSwingSide = fixDecimal(solution.lowProfileMount.mountHeightSwingSide)
        solution.lowProfileMount.mountHeightNonSwingSide = fixDecimal(solution.lowProfileMount.mountHeightNonSwingSide)
        solution.lowProfileMount.spacingSwingSide = fixDecimal(solution.lowProfileMount.spacingSwingSide)
        solution.lowProfileMount.spacingNonSwingSide = fixDecimal(solution.lowProfileMount.spacingNonSwingSide)

        solution.ceilingMount.mountHeightSwingSide = fixDecimal(solution.ceilingMount.mountHeightSwingSide)
        solution.ceilingMount.mountHeightNonSwingSide = fixDecimal(solution.ceilingMount.mountHeightNonSwingSide)
        solution.ceilingMount.spacingSwingSide = fixDecimal(solution.ceilingMount.spacingSwingSide)
        solution.ceilingMount.spacingNonSwingSide = fixDecimal(solution.ceilingMount.spacingNonSwingSide)

        solution.adhesiveMount.mountHeightSwingSide = fixDecimal(solution.adhesiveMount.mountHeightSwingSide)
        solution.adhesiveMount.mountHeightNonSwingSide = fixDecimal(solution.adhesiveMount.mountHeightNonSwingSide)
        solution.adhesiveMount.spacingSwingSide = fixDecimal(solution.adhesiveMount.spacingSwingSide)
        solution.adhesiveMount.spacingNonSwingSide = fixDecimal(solution.adhesiveMount.spacingNonSwingSide)

    }

    return solution
}

export default getMountingSolution