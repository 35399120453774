import getMountTypeParameters from "./getMountTypeParameters";
import {
    CONFIGURATION_OPTIONS,
    MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    ADHESIVE_MOUNT, DEEP_DOOR_OPENER_HEIGHT_CONSTANT, MAX_MOUNTING_HEIGHT,
} from "../../utils/settings";
import getMaxMountHeight from "./getMaxMountHeight";
import getPrescribedSensorLocation from "./getPrescribedSensorLocation";
import getSensorSpacing from "../../components/getSensorSpacing";
import handleMultiMPI from "./solutionFactors/handleMultiMPI";


const getAdhesiveMountSolution = (dimensionInputs, configurationOptions) => {
    let adhesiveMountSolution = {
        mountTypeSwingSide: null,
        mountTypeNonSwingSide: null,
        mountHeightSwingSide: 0,
        mountHeightNonSwingSide: 0,
        sensorCountSwingSide: 0,
        sensorCountNonSwingSide: 0,
        alignmentSwingSide: 'centered',
        alignmentNonSwingSide: 'centered',
        spacingSwingSide: 'N/A',
        spacingNonSwingSide: 'N/A',
        distanceFromAdhesiveSwingSide: null,
        distanceFromAdhesiveNonSwingSide: null,
        swingSideFail: true,
        nonSwingSideFail: true
    }



    let installParameters = getMountTypeParameters(ADHESIVE_MOUNT,
        dimensionInputs[0]['value'],
        dimensionInputs[1]['value'],
        dimensionInputs[2]['value'],
        dimensionInputs[3]['value'],)
    // console.log('Step #1 Result: Received the installParameters for Adhesive Mount --> ')
    // console.dir(installParameters)
    if(typeof installParameters.minimumMountHeightSwingSide != 'number') {
       adhesiveMountSolution.swingSideFail = true
    }
    if(typeof installParameters.minimumMountHeightNonSwingSide != 'number') {
        adhesiveMountSolution.nonSwingSideFail = true
    }

    // /*
    //     Step #2 If no door, raise min height to 100".
    //     If there isn't room to raise it that high, start with an MPI
    //     The purpose of this is to avoid having a sensor offset 10" from
    //     the door frame.  When there is no door, we want the sensor centered,
    //     or to be an MPI.
    //  */
    //
    // // Find the configurationOption for HAS_A_DOOR
    // for(let i = 0, l = configurationOptions.length; i < l; i++) {
    //     let currentConfigOption = configurationOptions[i];
    //     // If we find it and it is not checked (as in there is not a door)
    //     if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
    //         // console.log('This entryway does not have a door')
    //         //Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!adhesiveMountSolution.swingSideFail) {
    //             installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightSwingSide): installParameters.startingSensorCountSwingSide = 2
    //         }
    //
    //         //Non-Swing Side
    //         // If the maxMountingHeight is GEQ than 100"
    //         if(!adhesiveMountSolution.nonSwingSideFail) {
    //             installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
    //                 // Set the minMountingHeight to the max between 100" and minMountingHeight
    //                 installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
    //                     // Else, set the startingSensorCount = 2.
    //                     installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2
    //         }
    //
    //         break
    //     }
    // }
    // // console.log('Step #2 Result: Has a Door Step ')
    // // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)

    /*
        Step #3 If there are sliding doors, raise the min height to 100".
        If there isn't room to raise it that high, start with an MPI
        The purpose of this is to avoid having a sensor offset 10" from
        the door frame.  When there are sliding doors, we want the sensor centered,
        or to be an MPI
     */
    // Find the configurationOption for HAS_SLIDING_DOORS
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there are sliding doors)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_SLIDING_DOORS && currentConfigOption.isChecked) {
            // console.log('This entryway has sliding doors')
            //Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!adhesiveMountSolution.swingSideFail) {
                installParameters.maximumMountHeightSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
                    // Set the minMountingHeight to the max between 100" and minMountingHeight
                    installParameters.minimumMountHeightSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
                        // Else, set the startingSensorCount = 2.
                        installParameters.minimumMountHeightSwingSide): installParameters.startingSensorCountSwingSide = 2
            }

            //Non-Swing Side
            // If the maxMountingHeight is GEQ than 100"
            if(!adhesiveMountSolution.nonSwingSideFail) {
                installParameters.maximumMountHeightNonSwingSide >= MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME ?
                    // Set the minMountingHeight to the max between 100" and minMountingHeight
                    installParameters.minimumMountHeightNonSwingSide = Math.max(MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME,
                        // Else, set the startingSensorCount = 2.
                        installParameters.minimumMountHeightNonSwingSide): installParameters.startingSensorCountNonSwingSide = 2
            }

            break
        }
    }
    // console.log('Step #3 Result: Has sliding doors step ')
    // console.log('minMountHeightSwingSide --> ' + installParameters.minimumMountHeightSwingSide)
    // console.log('minMountHeightNonSwingSide --> ' + installParameters.minimumMountHeightNonSwingSide)
    // console.log('maxMountHeightSwingSide --> ' + installParameters.maximumMountHeightSwingSide)
    // console.log('maxMountHeightNonSwingSide --> ' + installParameters.maximumMountHeightNonSwingSide)

    /*
        Step #4 If there is a deep door closer, limit the mounting space to
        the door height + the opener height, and make the mount adhesive
        The purpose of this step is to ensure we can mount the sensor on the closer
        with an adhesive mount.
     */
    // Find the configurationOption for HAS_DEEP_DOOR_OPENER
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is a deep opener)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_DEEP_DOOR_OPENER && currentConfigOption.isChecked) {
            // console.log('This entryway has a deep door opener')
            //Swing Side
            if(currentConfigOption.side === 'swing') {
                // set the ceiling height to the min between the entryHeight + opener height + OFFSET_FROM_CEILING, and existing ceiling
                installParameters.ceilingHeightSwingSide = Math.min(
                    (installParameters.entryHeight + DEEP_DOOR_OPENER_HEIGHT_CONSTANT + ADHESIVE_MOUNT.OFFSET_FROM_CEILING),
                    installParameters.ceilingHeightSwingSide)

                //get the new maxMountHeight with the new ceilingHeight
                // console.log('Adhesive Mount - Going to get maxMountHeight. Parameters: ' +
                // installParameters.ceilingHeightSwingSide + ', ' +
                // installParameters.ceilingHeightNonSwingSide + ', ' +
                // installParameters.entryHeight)
                installParameters.maximumMountHeightSwingSide = getMaxMountHeight(
                    ADHESIVE_MOUNT,
                    'swing',
                    installParameters.ceilingHeightSwingSide,
                    installParameters.ceilingHeightNonSwingSide,
                    installParameters.entryHeight)

                adhesiveMountSolution.swingSideFail = false
                //console.log('Adhesive Mount - Got maxMountHeight: ' + installParameters.maximumMountHeightSwingSide)

            }
            //Non-swing Side
            // We just assume that the opener is on the swing side.
            //future - ask which side and only calc the side it's on

            break
        }


        // I'm not sure if we need to do this
        // validate that it will fit (ceiling height becomes door height + 6" + ceiling offset)
    }
    // console.log('Step #4 Result: Has a Deep door closer step ')
    // console.log('ceilingHeightSwingSide --> ' + installParameters.ceilingHeightSwingSide)
    // console.log('ceilingHeightNonSwingSide --> ' + installParameters.ceilingHeightNonSwingSide)
    // console.log('mountType --> ' + installParameters.mountType)

    /*
        Step #5 If the wall is glass, make it adhesive
     */
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is a glass wall)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_GLASS_WALL && currentConfigOption.isChecked) {
           adhesiveMountSolution.swingSideFail = false;
           adhesiveMountSolution.nonSwingSideFail = false;
        }
    }
    // console.log('Step #5 Result: Has a glass wall step ')
    // console.log('mountType --> ' + installParameters.mountType)

    /*
        Step #6 If ambient light, make starting sensor count = 2
     */
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is checked (as in there is ambient light)
        if(currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_AMBIENT_LIGHT && currentConfigOption.isChecked) {
            installParameters.startingSensorCountSwingSide = 2
            installParameters.startingSensorCountNonSwingSide = 2
        }
    }
    // console.log('Step #6 Result: Has ambient light step ')
    // console.log('startingSensorCountSwingSide --> ' + installParameters.startingSensorCountSwingSide)
    // console.log('startingSensorCountNonSwingSide --> ' + installParameters.startingSensorCountNonSwingSide)

    /*
        Step #7 Calculate the sensor height
     */
    // console.log('Adhesive Mount - Going to get sensors prescribed height')
    // console.log('maxMounting Height to be used: ' + installParameters.maximumMountHeightSwingSide)
    let prescribedLocation = getPrescribedSensorLocation(installParameters)
    adhesiveMountSolution.mountHeightSwingSide = prescribedLocation.prescribedHeightSwingSide
    adhesiveMountSolution.sensorCountSwingSide = prescribedLocation.prescribedCountSwingSide
    adhesiveMountSolution.mountHeightNonSwingSide = prescribedLocation.prescribedHeightNonSwingSide
    adhesiveMountSolution.sensorCountNonSwingSide = prescribedLocation.prescribedCountNonSwingSide
    // console.log('Adhesive Mount - Got prescribed height')
    // console.log('adhesive mount prescribed height: ' + prescribedLocation.prescribedHeightSwingSide +
    // ' with ' + prescribedLocation.prescribedCountSwingSide + ' sensors')


    /*
        Step #8 If sensor count = 1 and height is < 100", and there is a door, offset sensor 10" from door handle side
     */
    let hasADoor = true;
    // Find the configurationOption for HAS_A_DOOR
    for(let i = 0, l = configurationOptions.length; i < l; i++) {
        let currentConfigOption = configurationOptions[i];
        // If we find it and it is not checked (as in there is not a door)
        if (currentConfigOption.id === CONFIGURATION_OPTIONS.HAS_A_DOOR && !currentConfigOption.isChecked) {
            // console.log('This entryway does not have a door')
            hasADoor = false;
            break;
        }
    }

    adhesiveMountSolution.alignmentSwingSide = (prescribedLocation.prescribedCountSwingSide === 1 && prescribedLocation.prescribedHeightSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';

    adhesiveMountSolution.alignmentNonSwingSide = (prescribedLocation.prescribedCountNonSwingSide === 1 && prescribedLocation.prescribedHeightNonSwingSide < MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME && hasADoor) ?
        'Offset' : 'Centered';


    /*
        Step #9 if MPI > 2, and prescribed height is less than 95", move to 95" and validate that it will fit.
     */
    adhesiveMountSolution.mountHeightSwingSide = handleMultiMPI(ADHESIVE_MOUNT,'swing', adhesiveMountSolution.sensorCountSwingSide, adhesiveMountSolution.mountHeightSwingSide,installParameters.maximumMountHeightSwingSide)
    adhesiveMountSolution.mountHeightNonSwingSide = handleMultiMPI(ADHESIVE_MOUNT,'non-swing', adhesiveMountSolution.sensorCountNonSwingSide, adhesiveMountSolution.mountHeightNonSwingSide,installParameters.maximumMountHeightNonSwingSide)
    if (typeof adhesiveMountSolution.mountHeightSwingSide !== 'number') {
        adhesiveMountSolution.swingSideFail = true;
    }
    if (typeof adhesiveMountSolution.mountHeightNonSwingSide !== 'number') {
        adhesiveMountSolution.nonSwingSideFail = true;
    }

    /*
        Step #10 Calculate the spacing for the MPI
     */

    adhesiveMountSolution.spacingSwingSide = (adhesiveMountSolution.sensorCountSwingSide > 1 && adhesiveMountSolution.mountHeightSwingSide !== 'N/A') ?
        getSensorSpacing(adhesiveMountSolution.mountHeightSwingSide) : 'N/A'

    adhesiveMountSolution.spacingNonSwingSide = (adhesiveMountSolution.sensorCountNonSwingSide > 1 && adhesiveMountSolution.mountHeightNonSwingSide !== 'N/A') ?
        getSensorSpacing(adhesiveMountSolution.mountHeightNonSwingSide) : 'N/A'


    /**
     * Make sure that the solution does not exceed the MAX_MOUNTING_HEIGHT
     */
    if(adhesiveMountSolution.mountHeightSwingSide > MAX_MOUNTING_HEIGHT) {
        adhesiveMountSolution.swingSideFail = true;
    }
    if(adhesiveMountSolution.mountHeightNonSwingSide > MAX_MOUNTING_HEIGHT) {
        adhesiveMountSolution.nonSwingSideFail = true;
    }


    return adhesiveMountSolution
}

export default getAdhesiveMountSolution