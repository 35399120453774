import {SENSOR_HEIGHT_FORMULAS} from "../../utils/settings";

const getPrescribedSensorLocation = (installParameters) => {
    let result = {
        prescribedHeightSwingSide: 0,
        prescribedCountSwingSide: 0,
        prescribedHeightNonSwingSide: 0,
        prescribedCountNonSwingSide: 0,
    }
    // take in the starting sensor count and min and max mounting height
    // apply the formula.  if the height is lower than the min,
    // make the height = the min.
    // if the height is higher than the max, add 1 and loop around again.
    // return the height and count.
    // for(let i = 0, l = configurationOptions.length; i < l; i++) {
    for(let i = installParameters.startingSensorCountSwingSide, l = 9; i < l; i++) {
        let formula = SENSOR_HEIGHT_FORMULAS[i]
       console.log('Calculating for ' + i + ' sensor(s) on the swing side')
       // console.log('Formula Deets --> Slope: ' + formula.slope + ' and yIntercept: ' + formula.yIntercept)

        // height = slope * width + yIntercept
        result.prescribedHeightSwingSide = ((formula.slope * parseFloat(installParameters.entryWidth)) + formula.yIntercept)
        result.prescribedCountSwingSide = i
       console.log('installParameters minMountHeight --> ' + installParameters.minimumMountHeightSwingSide)
       console.log('prescribedSwingSide --> ' + result.prescribedHeightSwingSide)
        // If the sensor will not physically fit
        if(installParameters.minimumMountHeightSwingSide === 'Will Not Fit') {
            result.prescribedHeightSwingSide = 'N/A'
            break
        }
        if(result.prescribedHeightSwingSide < installParameters.minimumMountHeightSwingSide) {
          // console.log('Increasing Swing Side height from: ' + result.prescribedHeightSwingSide + ' to: ' + installParameters.minimumMountHeightSwingSide)
            result.prescribedHeightSwingSide = installParameters.minimumMountHeightSwingSide

        }

        if(result.prescribedHeightSwingSide <= installParameters.maximumMountHeightSwingSide) {
            break
        }

    }
   // console.log('Final prescribedSwingSide --> ' + result.prescribedHeightSwingSide)

    for(let i = installParameters.startingSensorCountNonSwingSide, l = 9; i < l; i++) {
        let formula = SENSOR_HEIGHT_FORMULAS[i]
      console.log('Calculating for ' + i + ' sensor(s) on the non-swing side')
      //  console.log('Formula Deets --> Slope: ' + formula.slope + ' and yIntercept: ' + formula.yIntercept)

        // height = slope * width + yIntercept
        result.prescribedHeightNonSwingSide = ((formula.slope * parseFloat(installParameters.entryWidth)) + formula.yIntercept)
        result.prescribedCountNonSwingSide = i
       console.log('installParameters minMountHeight --> ' + installParameters.minimumMountHeightNonSwingSide)
        console.log('prescribedNonSwingSide --> ' + result.prescribedHeightNonSwingSide)
        // If the sensor will not physically fit
        if(installParameters.minimumMountHeightNonSwingSide === 'Will Not Fit') {
            result.prescribedHeightNonSwingSide = 'N/A'
            break
        }
        if(result.prescribedHeightNonSwingSide < installParameters.minimumMountHeightNonSwingSide) {
            result.prescribedHeightNonSwingSide = installParameters.minimumMountHeightNonSwingSide
        }

        if(result.prescribedHeightNonSwingSide <= installParameters.maximumMountHeightNonSwingSide) {
            break
        }
    }


    return result
}

export default getPrescribedSensorLocation