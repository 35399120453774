import {InputAdornment, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import getSensorSpacing from "../components/getSensorSpacing";
import {MAX_MOUNTING_HEIGHT, MIN_MOUNTING_HEIGHT} from "../utils/settings";


const MpiCalculator = () => {
    const [spacing, setSpacing] = useState(0)

    const handleChange = (e) => {
        if (e.target.value >= MIN_MOUNTING_HEIGHT && e.target.value <= MAX_MOUNTING_HEIGHT) {
            setSpacing((spacing) => getSensorSpacing(e.target.value))
        } else {
            setSpacing((spacing) => 0)
        }


    }

    return(
        <div>
            <Typography color="inherit" variant="h5" component="h1">
                Calculates sensor spacing for MPIs
            </Typography>
            <TextField
                className="dimension-input"
                label="Sensor Height"
                onChange={handleChange}
                margin="dense"
                size="small"
                color="primary"
                variant="outlined"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{"in"}</InputAdornment>,
                }}
            />
            <Typography color="inherit" variant="h5" component="h1">
                Space the sensors {spacing} inches apart
            </Typography>
        </div>
    )
}

export default MpiCalculator