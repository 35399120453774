
import ResultsDisplay from "./entryCalculator/components/ResultsDisplay";
import {createMuiTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import Header from "./components/Header";
import InputsDisplay from "./entryCalculator/components/InputsDisplay";
import {useEffect, useState} from "react";
import {CONFIGURATION_OPTIONS} from "./utils/settings";
import getMountingSolution from "./entryCalculator/functions/getMountingSolution";
import EntryCalculator from "./entryCalculator/entryCalculator";
import MpiCalculator from "./mpiCalculator/mpiCalculator";
import PowerCalculator from "./powerCalculator/powerCalculator";



let theme = createMuiTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#18202c',
            },
        },
        MuiButton: {
            label: {
                textTransform: 'none',
            },
            contained: {
                boxShadow: 'none',
                '&:active': {
                    boxShadow: 'none',
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: 'none',
                margin: '0 16px',
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up('md')]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#404854',
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: 'inherit',
                marginRight: 0,
                '& svg': {
                    fontSize: 20,
                },
            },
        },

    },
};

const drawerWidth = 256;

const styles = {
    root: {
        display: 'flex',
        minHeight: '100vh',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        padding: theme.spacing(6, 4),
        background: '#eaeff1',
    },
};


const App = props => {
    const { classes } = props;
// const Calculator = props => {
//     const { classes } = props;
//
//     const [selectedUnits, setSelectedUnits] = useState("in");
//
//
//
//
//
//
//     const [configurationOptions, setConfigurationOptions] = useState(
//         [
//             {
//                 id: CONFIGURATION_OPTIONS.HAS_A_DOOR,
//                 text: 'Does the entryway have a door?',
//                 isChecked: true
//             },
//             // {
//             //     id: CONFIGURATION_OPTIONS.HAS_A_CORRIDOR,
//             //     text: 'Does the entryway have a constraining corridor?',
//             //     isChecked: false
//             // },
//             {
//                 id: CONFIGURATION_OPTIONS.HAS_AMBIENT_LIGHT,
//                 text: 'Is there a lot of ambient light?',
//                 isChecked: false
//             },
//             {
//                 id: CONFIGURATION_OPTIONS.HAS_DEEP_DOOR_OPENER,
//                 text: 'Is there a door opener deeper than 6"?',
//                 isChecked: false,
//                 side: 'swing',
//                 depth: 0,
//                 height: 0,
//             },
//             {
//                 id: CONFIGURATION_OPTIONS.HAS_GLASS_WALL,
//                 text: 'Is the wall glass?',
//                 isChecked: false
//             },
//             {
//                 id: CONFIGURATION_OPTIONS.HAS_SLIDING_DOORS,
//                 text: 'Are the doors sliding doors?',
//                 isChecked: false
//             }
//
//         ]
//     )
//     const [dimensionInputs, setDimensionInputs] = useState(
//         [
//             {
//                 id: 'passthroughWidth',
//                 label: 'Passthrough Width',
//                 value: null
//             },
//             {
//                 id: 'passthroughHeight',
//                 label: 'Passthrough Height',
//                 value: null
//             },
//             {
//                 id: 'ceilingHeightNonSwingSide',
//                 label: 'Ceiling Height (Non-Swing Side)',
//                 value: null
//             },
//             {
//                 id: 'ceilingHeightSwingSide',
//                 label: 'Ceiling Height (Swing Side)',
//                 value: null
//             },
//         ]
//     )
//
//
//     const [results, setResults] = useState(
//         {
//             wallMount: {
//                 mountTypeSwingSide: null,
//                 mountTypeNonSwingSide: null,
//                 mountHeightSwingSide: 0,
//                 mountHeightNonSwingSide: 0,
//                 sensorCountSwingSide: 0,
//                 sensorCountNonSwingSide: 0,
//                 alignmentSwingSide: 'centered',
//                 alignmentNonSwingSide: 'centered',
//                 spacingSwingSide: 'N/A',
//                 spacingNonSwingSide: 'N/A',
//                 distanceFromWallSwingSide: null,
//                 distanceFromWallNonSwingSide: null,
//                 swingSideFail: false,
//                 nonSwingSideFail: false
//             },
//             lowProfileMount: {
//                 mountTypeSwingSide: null,
//                 mountTypeNonSwingSide: null,
//                 mountHeightSwingSide: 0,
//                 mountHeightNonSwingSide: 0,
//                 sensorCountSwingSide: 0,
//                 sensorCountNonSwingSide: 0,
//                 alignmentSwingSide: 'centered',
//                 alignmentNonSwingSide: 'centered',
//                 spacingSwingSide: 'N/A',
//                 spacingNonSwingSide: 'N/A',
//                 distanceFromWallSwingSide: null,
//                 distanceFromWallNonSwingSide: null,
//                 swingSideFail: false,
//                 nonSwingSideFail: false,
//             },
//             ceilingMount: {
//                 mountTypeSwingSide: null,
//                 mountTypeNonSwingSide: null,
//                 mountHeightSwingSide: 0,
//                 mountHeightNonSwingSide: 0,
//                 sensorCountSwingSide: 0,
//                 sensorCountNonSwingSide: 0,
//                 alignmentSwingSide: 'centered',
//                 alignmentNonSwingSide: 'centered',
//                 spacingSwingSide: 'N/A',
//                 spacingNonSwingSide: 'N/A',
//                 distanceFromWallSwingSide: 0,
//                 distanceFromWallNonSwingSide: 0,
//                 swingSideFail: false,
//                 nonSwingSideFail: false
//             },
//             adhesiveMount: {
//                 mountTypeSwingSide: null,
//                 mountTypeNonSwingSide: null,
//                 mountHeightSwingSide: 0,
//                 mountHeightNonSwingSide: 0,
//                 sensorCountSwingSide: 0,
//                 sensorCountNonSwingSide: 0,
//                 alignmentSwingSide: 'centered',
//                 alignmentNonSwingSide: 'centered',
//                 spacingSwingSide: 'N/A',
//                 spacingNonSwingSide: 'N/A',
//                 distanceFromWallSwingSide: null,
//                 distanceFromWallNonSwingSide: null,
//                 swingSideFail: false,
//                 nonSwingSideFail: false
//             }
//         }
//     )
//
//     const onUnitChangeButtonClick = (unitValue) => {
//
//         //  let tempDimensions = dimensionInputs;
//         //
//         // for(let i = 0; i < dimensionInputs.length; i++) {
//         //     tempDimensions[i].value = convert(tempDimensions[i].value).from(selectedUnits).to(unitValue)
//         // }
//         //setDimensionInputs(dimensionInputs => tempDimensions)
//         setSelectedUnits(unitValue);
//
//     }
//
//
//
//     const onInputChange = index => e => {
//
//         // console.log(index)
//         // console.log(e.target.value)
//         let mIndex = dimensionInputs.findIndex(x=> x.id === index);
//         let temporaryArray = dimensionInputs.slice();
//         temporaryArray[mIndex]["value"] = e.target.value
//         setDimensionInputs(temporaryArray);
//         setResults(getMountingSolution(dimensionInputs, configurationOptions, selectedUnits))
//
//     }
//     const onCheckboxChange = index => e => {
//         let mIndex = configurationOptions.findIndex(x=> x.id === index);
//         let temporaryArray = configurationOptions.slice();
//         temporaryArray[mIndex]["isChecked"] = e.target.checked
//         setConfigurationOptions(temporaryArray);
//        // console.log('Set ' + index + ' to: ' + e.target.checked)
//         //TODO get this out of here
//
//
//
//         setResults(getMountingSolution(dimensionInputs, configurationOptions, selectedUnits))
//
//
//     }

    const [tabValue, setTabValue] = useState(0)
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };







  return (
      <ThemeProvider theme={theme}>
          <div className={classes.root}>
              <CssBaseline />
              <div className={classes.app}>
                  <Header
                  value = {tabValue}
                  handleChange = {handleTabChange}
                  ></Header>
                  <div>
                      {tabValue === 0 ? <main className={classes.main}>
                          <EntryCalculator></EntryCalculator>
                      </main> : null}
                  </div>
                  <div>
                      {tabValue === 1 ? <main className={classes.main}>
                          <MpiCalculator></MpiCalculator>
                      </main> : null}
                  </div>
                  <div>
                      {tabValue === 2 ? <main className={classes.main}>
                          <PowerCalculator></PowerCalculator>
                      </main> : null }
                  </div>


              </div>
          </div>
      </ThemeProvider>

  )
}


// const EntryCalculator = () => {
//     //TODO Implement the calculator reset here.  See https://jscomplete.com/playground/rgs3.9 for reference.
//   // const [calculatorId, setCalculatorId] = useState(1);
//   // return <Calculator key={calculatorId} startNewCalculator{() => setCalculatorId(calculatorId + 1)}/>
//     return <Calculator/>
// }




App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);