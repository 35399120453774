export const MAX_MOUNTING_HEIGHT = 120;
export const MIN_MOUNTING_HEIGHT = 90;
export const PREFERRED_MIN_MOUNTING_HEIGHT = 95;
export const MULTI_MPI_MIN_MOUNTING_HEIGHT = 95;
export const MAX_HEIGHT_ABOVE_DOOR_BEFORE_OFFSET_FROM_WALL = 10;


export const CONFIGURATION_OPTIONS = {
    HAS_A_DOOR: 'hasADoor',
    HAS_A_CORRIDOR: 'hasACorridor',
    HAS_AMBIENT_LIGHT: 'hasAmbientLight',
    HAS_DEEP_DOOR_OPENER: 'hasDoorOpener',
    HAS_GLASS_WALL: 'hasGlassWall',
    HAS_SLIDING_DOORS: 'hasSlidingDoors'
}

export const WALL_MOUNT = {
    OFFSET_FROM_CEILING: 2,
    OFFSET_FROM_TOP_OF_DOOR: 2.5,
    MIN_SPACE_NEEDED: 4.5,
    MAX_DISTANCE_ABOVE_DOOR: 10,
    CAN_MOUNT_LOWER_THAN_DOOR: false,
}
export const LOW_PROFILE_MOUNT = {
    OFFSET_FROM_CEILING: 1.25,
    OFFSET_FROM_TOP_OF_DOOR: 2.5,
    MIN_SPACE_NEEDED: 3.75,
    MAX_DISTANCE_ABOVE_DOOR: 10,
    CAN_MOUNT_LOWER_THAN_DOOR: false,
}
export const CEILING_MOUNT = {
    OFFSET_FROM_CEILING: 2,
    OFFSET_FROM_TOP_OF_DOOR: 1.13,
    MIN_SPACE_NEEDED: 3.13,
    MAX_DISTANCE_ABOVE_DOOR: null,
    CAN_MOUNT_LOWER_THAN_DOOR: true,
}
export const ADHESIVE_MOUNT = {
    OFFSET_FROM_CEILING: 2,
    OFFSET_FROM_TOP_OF_DOOR: 2.5,
    MIN_SPACE_NEEDED: 4.5,
    MAX_DISTANCE_ABOVE_DOOR: 10,
    CAN_MOUNT_LOWER_THAN_DOOR: false,
}



export const BACK_OF_SENSOR_HEIGHT = 2.38;
export const FRONT_OF_SENSOR_HEIGHT = 1.25;
export const SENSOR_HEIGHT_WITH_WALL_BRACKET = 3.75;

export const MAX_HEIGHT_TO_OFFSET_SENSOR_FROM_FRAME = 100;
export const DEEP_DOOR_OPENER_HEIGHT_CONSTANT = 10; //Future - Make this a configuration option input


export const SENSOR_HEIGHT_FORMULAS = [
    {slope: 0, yIntercept: 0}, // Element 0 is nothing.  Starts with element 1 to align with sensor count
    {slope: 0.6, yIntercept: 66},
    {slope: 0.333, yIntercept: 69.3},
    {slope: 0.222, yIntercept: 71.3},
    {slope: 0.167, yIntercept: 73.4},
    {slope: 0.133, yIntercept: 72.9},
    {slope: 0.111, yIntercept: 73.1},
    {slope: 0.0952, yIntercept: 71.9},
    {slope: 0.0833, yIntercept: 72.3}
]
export const SENSOR_SPACING_FORMULA = { slope: 1.5, yIntercept: -113};