import DimensionInput from "./DimensionInput";


const DimensionsInputDisplay = props => {

    return (
        <div>
            {props.dimensions.map(dimension => (
                <DimensionInput
                    key={dimension.id}
                    id={dimension.id}
                    label={dimension.label}
                    onChange={props.onChange(dimension.id)}
                    selectedUnits={props.selectedUnits}
                >
                    {dimension.id}
                </DimensionInput>
            ))}
        </div>
    )

}

export default DimensionsInputDisplay