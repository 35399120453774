import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";

const ResultsTable = props => {

    const rows = props.rows;


    return (
            <TableContainer component={Paper}  style={{width: '50%', marginTop: "16px"}}>
                <Table className='table' aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Typography variant="h4" component="h4" gutterBottom>{props.tableTitle}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mount Type</TableCell>
                            <TableCell># of Sensors</TableCell>
                            <TableCell>Height</TableCell>
                            <TableCell>Alignment</TableCell>
                            <TableCell>Spacing</TableCell>
                            <TableCell>Dist. from Wall</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.mountType} style={ row.fail ? {textDecoration : 'line-through'} : {}}>
                                <TableCell component="th" scope="row">
                                    {row.mountType}
                                </TableCell>
                                {/*<TableCell align="center">{row.fail ? '' : row.numOfSensors}</TableCell>*/}
                                {/*<TableCell align="center">{row.fail ? '' : row.height}</TableCell>*/}
                                {/*<TableCell align="center">{row.fail ? '' : row.alignment}</TableCell>*/}
                                {/*<TableCell align="center">{row.fail ? '' : row.spacing}</TableCell>*/}
                                {/*<TableCell align="center">{row.fail ? '' : row.distFromWall}</TableCell>*/}
                                <TableCell align="center">{row.numOfSensors}</TableCell>
                                <TableCell align="center">{row.height}</TableCell>
                                <TableCell align="center">{row.alignment}</TableCell>
                                <TableCell align="center">{row.spacing}</TableCell>
                                <TableCell align="center">{row.distFromWall}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>



    )
}

export default ResultsTable