import {useState} from "react";
import {CONFIGURATION_OPTIONS} from "../utils/settings";
import getMountingSolution from "./functions/getMountingSolution";
import InputsDisplay from "./components/InputsDisplay";
import ResultsDisplay from "./components/ResultsDisplay";

const EntryCalculator = props => {


    const [selectedUnits, setSelectedUnits] = useState("in");


    const [configurationOptions, setConfigurationOptions] = useState(
        [
            {
                id: CONFIGURATION_OPTIONS.HAS_A_DOOR,
                text: 'Does the entryway have a door?',
                isChecked: true
            },
            // {
            //     id: CONFIGURATION_OPTIONS.HAS_A_CORRIDOR,
            //     text: 'Does the entryway have a constraining corridor?',
            //     isChecked: false
            // },
            {
                id: CONFIGURATION_OPTIONS.HAS_AMBIENT_LIGHT,
                text: 'Is there a lot of ambient light?',
                isChecked: false
            },
            {
                id: CONFIGURATION_OPTIONS.HAS_DEEP_DOOR_OPENER,
                text: 'Is there a door opener deeper than 6"?',
                isChecked: false,
                side: 'swing',
                depth: 0,
                height: 0,
            },
            {
                id: CONFIGURATION_OPTIONS.HAS_GLASS_WALL,
                text: 'Is the wall glass?',
                isChecked: false
            },
            {
                id: CONFIGURATION_OPTIONS.HAS_SLIDING_DOORS,
                text: 'Are the doors sliding doors?',
                isChecked: false
            }

        ]
    )
    const [dimensionInputs, setDimensionInputs] = useState(
        [
            {
                id: 'passthroughWidth',
                label: 'Passthrough Width',
                value: null
            },
            {
                id: 'passthroughHeight',
                label: 'Passthrough Height',
                value: null
            },
            {
                id: 'ceilingHeightNonSwingSide',
                label: 'Ceiling Height (Non-Swing Side)',
                value: null
            },
            {
                id: 'ceilingHeightSwingSide',
                label: 'Ceiling Height (Swing Side)',
                value: null
            },
        ]
    )


    const [results, setResults] = useState(
        {
            wallMount: {
                mountTypeSwingSide: null,
                mountTypeNonSwingSide: null,
                mountHeightSwingSide: 0,
                mountHeightNonSwingSide: 0,
                sensorCountSwingSide: 0,
                sensorCountNonSwingSide: 0,
                alignmentSwingSide: 'centered',
                alignmentNonSwingSide: 'centered',
                spacingSwingSide: 'N/A',
                spacingNonSwingSide: 'N/A',
                distanceFromWallSwingSide: null,
                distanceFromWallNonSwingSide: null,
                swingSideFail: false,
                nonSwingSideFail: false
            },
            lowProfileMount: {
                mountTypeSwingSide: null,
                mountTypeNonSwingSide: null,
                mountHeightSwingSide: 0,
                mountHeightNonSwingSide: 0,
                sensorCountSwingSide: 0,
                sensorCountNonSwingSide: 0,
                alignmentSwingSide: 'centered',
                alignmentNonSwingSide: 'centered',
                spacingSwingSide: 'N/A',
                spacingNonSwingSide: 'N/A',
                distanceFromWallSwingSide: null,
                distanceFromWallNonSwingSide: null,
                swingSideFail: false,
                nonSwingSideFail: false,
            },
            ceilingMount: {
                mountTypeSwingSide: null,
                mountTypeNonSwingSide: null,
                mountHeightSwingSide: 0,
                mountHeightNonSwingSide: 0,
                sensorCountSwingSide: 0,
                sensorCountNonSwingSide: 0,
                alignmentSwingSide: 'centered',
                alignmentNonSwingSide: 'centered',
                spacingSwingSide: 'N/A',
                spacingNonSwingSide: 'N/A',
                distanceFromWallSwingSide: 0,
                distanceFromWallNonSwingSide: 0,
                swingSideFail: false,
                nonSwingSideFail: false
            },
            adhesiveMount: {
                mountTypeSwingSide: null,
                mountTypeNonSwingSide: null,
                mountHeightSwingSide: 0,
                mountHeightNonSwingSide: 0,
                sensorCountSwingSide: 0,
                sensorCountNonSwingSide: 0,
                alignmentSwingSide: 'centered',
                alignmentNonSwingSide: 'centered',
                spacingSwingSide: 'N/A',
                spacingNonSwingSide: 'N/A',
                distanceFromWallSwingSide: null,
                distanceFromWallNonSwingSide: null,
                swingSideFail: false,
                nonSwingSideFail: false
            }
        }
    )

    const onUnitChangeButtonClick = (unitValue) => {

        //  let tempDimensions = dimensionInputs;
        //
        // for(let i = 0; i < dimensionInputs.length; i++) {
        //     tempDimensions[i].value = convert(tempDimensions[i].value).from(selectedUnits).to(unitValue)
        // }
        //setDimensionInputs(dimensionInputs => tempDimensions)
        setSelectedUnits(unitValue);

    }


    const onInputChange = index => e => {

        // console.log(index)
        // console.log(e.target.value)
        let mIndex = dimensionInputs.findIndex(x => x.id === index);
        let temporaryArray = dimensionInputs.slice();
        temporaryArray[mIndex]["value"] = e.target.value
        setDimensionInputs(temporaryArray);
        setResults(getMountingSolution(dimensionInputs, configurationOptions, selectedUnits))

    }
    const onCheckboxChange = index => e => {
        let mIndex = configurationOptions.findIndex(x => x.id === index);
        let temporaryArray = configurationOptions.slice();
        temporaryArray[mIndex]["isChecked"] = e.target.checked
        setConfigurationOptions(temporaryArray);
        // console.log('Set ' + index + ' to: ' + e.target.checked)
        //TODO get this out of here


        setResults(getMountingSolution(dimensionInputs, configurationOptions, selectedUnits))


    }

    return (
        <div>
        <InputsDisplay
            selectedUnits = {selectedUnits}
            onUnitChangeButtonClick = {onUnitChangeButtonClick}
            onInputChange = {onInputChange}
            dimensionInputs =  {dimensionInputs}
            configurationOptions = {configurationOptions}
            onCheckboxChange = {onCheckboxChange}

        > </InputsDisplay>
        <ResultsDisplay
            results = {results}
        > </ResultsDisplay>

        </div>


    )
}

export default EntryCalculator