import {InputAdornment, TextField} from "@material-ui/core";

const DimensionInput = props => {
    return (
        <TextField
            className="dimension-input"
            id={props.id}
            label={props.label}
            onChange={props.onChange}
            margin="dense"
            size="small"
            color="primary"
            variant="outlined"
            InputProps={{
                endAdornment: <InputAdornment position="end">{props.selectedUnits}</InputAdornment>,
            }}
        />
    )
}

export default DimensionInput