

/*
   This function takes in the mounting parameters and will return true or false depending on if
   the sensor will physically fit where prescribed.
    */
import {MIN_MOUNTING_HEIGHT} from "../../utils/settings";

const validateSensorFit = (mountType, doorSide, entryHeight, minMountHeight, maxMountHeight) => {
    if(((maxMountHeight - parseFloat(mountType.MIN_SPACE_NEEDED)) >= entryHeight) ||
        (doorSide === 'non-swing' &&  mountType.CAN_MOUNT_LOWER_THAN_DOOR)) {

         console.log('there is enough space --> ' + minMountHeight)
        if (minMountHeight >= MIN_MOUNTING_HEIGHT) {
             console.log('Yay! ' + minMountHeight)
            return true
        } else {
             console.log('Screwed again ' + minMountHeight)
            return false
        }
    } else {
         console.log('I think you are screwed')
        return false
    }

}

export default validateSensorFit